* {
  word-wrap: break-word;
  word-break: break-word;
}

.App {
  text-align: center;
  background-color: rgb(0, 0, 0);
  font-family: monospace;
  width: 100vw;
  word-wrap: break-word;
}

p {
  font-size: 1.2em;
}

#floatingNav {
  width: 100%;
  height: 25px;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.header {
  display: flex;
  flex-flow: column;
}

.header .pageTitle {
  color: rgb(61, 255, 61);
  font-size: 55px;
  margin-bottom: 50px;
}

.pageTitle::after {
  content: "";
  top: 0;
  right: -15px;
  display: inline-block;
  background-color: #606060;
  vertical-align: bottom;
  width: 25px;
  height: 55px;
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.headerIndex a {
  font-size: 1.4em;
  color: rgb(0, 255, 255);
  margin-top: 10px;
  text-align: left;
  display: block;
}

.aboutCard a, .videoCard a, .updatesCardContainer li > a {
  color: rgb(0, 255, 255);
  display: inline-block;
  font-size: 1.1em;
}

.aboutCard a, .videoCard a, .updatesCardContainer li > p {
  font-size: 1.1em;
  vertical-align: text-middle;
}

.updatesCardContainer li > * {
  margin: .2em
}

.updatesCardContainer > p {
  margin: 0
}

.updatesButton {
  margin-top: 20px;
  margin-bottom: 5px;
  color: rgb(61, 255, 61);
  background-color: black;
  font-size: 1em;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(255, 255, 255);
  padding: .4em;
}


.noticeImage {
  margin-top: 20px;
  max-width: 100%;
  word-wrap: break-word;
}

.header .category {
  margin-top: 0;
}

.CardContainer{
  display: flex;
  flex-flow: column;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.hidingCardContaier {
  display: none;
}

.card, .dateCard, .headerIndex, .forageCard, .aboutCard, .indexImageCard, .videoCard, .updatesCardContainer {
  padding: 0em 3em 1em 3em;
  max-width: 100%;
  margin: auto;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 9px;
  border-color: rgb(255, 255, 255) !important;
}

.dateCard li, .forageCard li {
  font-size: 1.2em;
}

.closedGrave {
  max-width: 3%;
  margin-left: 1%;
  margin-right: 1%;
  position: relative;
  bottom: -10px;
}

@media screen and (min-width: 950px){
  .forageCard {
    width: 70%;
  }

  .aboutCard, .updatesCardContainer {
    width: 50%;
    margin-bottom: 50px;
  }

  .videoCard {
    min-width: 70%;
    max-width: 100%;
    margin-bottom: 50px;
    max-height: 95vw;
  }
}

#notices {
  margin-top: 50px;
}

.imageIndexGrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 100px;
  margin: 3em 3em 3em 3em;
}

.indexImageCard img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.indexImageCard img:hover {
  transform: scale(1.2);
}

.card > * {
  display: block;
}

.card .title, .dateTitle, .forageTitle {
  color: rgb(0, 204, 0);
  margin-bottom: 5px;
}

.card .summary {
  color: rgb(255, 0, 255);
  margin-bottom: 5px;
}

.card .link {
  color: rgb(0, 255, 255);
  margin: 0px;
  margin-bottom: .6em;
  font-size: 1.2em;
}

.card .suggestedFollow, .dateItem, .forageItem, .aboutCard p, .updatesCardContainer p {
  color: rgb(255, 255, 0);
  margin-bottom: 2px;
}

.dateTitle, .dateItem, .forageItem, .aboutCard p, .updatesCardContainer p {
  text-align: left;
}

.periodList {
  margin: 0;
}

.category {
  color: rgb(176, 51, 249);
  display: inline-block;
  margin-top: 50px;
}

.wip {
  color: rgb(255, 255, 0);
  display: inline-block;
  position: relative;
  bottom: 2px;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  padding: 10px;
}

#playButton {
  width: 20%;
  height: 20%;
  margin-top: 10px;
}

.playText {
  color: rgb(0, 204, 0);
  background-color: black;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 1.4em;
  padding: 5px 10px;
}

#videoPlayer {
  width: 100%;
  height: 100%;
}

video {
  max-height: 60vh;
}

.updates h4 {
  font-size: 1.3em;
  color: rgb(255, 255, 0);
}

.updates ul {
  display: flex;
  flex-flow: column;
  text-align: left;
  color: rgb(255, 255, 0);
  margin: 0
}

.localInfo {
  color: rgb(255, 255, 0);
  font-size: 1.4em;
  margin: .1em 0 .1em 0;
}

.localInfoBand {
  color: rgb(255, 255, 0);
  font-size: 1.9em;
  margin: .1em 0 .1em 0;
  animation: blinker 1.5s linear infinite;
}

@media screen and (max-width: 950px){
  .CardContainer{
    width: 95%;
  }
  .aboutCard, .updatesCardContainer {
    margin-bottom: 50px;
  }
  .videoCard  {
    width: 80%;
  }
  .closedGrave {
    display: None;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
